<template>
  <div class="my_test_paper">

    <CommonTopSearch ref="CommonTopSearchRef"
                     @initData="getPaperList" />
    <!-- :style="{'margin-right':(index+1) % 4 == 0 ? '0' : '12px'}" -->
    <div class="papers">
      <div class="paper"
           v-for="(item,index) in paperList"
           :key="index">
        <div class="warp">
          <div class="teacher_name">
            出卷教师:{{item.edu_user_name}}
          </div>
          <div class="content">
            <div class="content_txt">{{item.paper_name}}</div>

            <div class="score">
              总分:{{item.paper_score}}
            </div>
          </div>
        </div>
        <div class="btn_warp"
             @click="toPaperInfo(item)">
          <div> 查看详情</div>
          <!--  <img src="@/static/teacher_paper/start.png"
               alt=""> -->
        </div>
        <div class="time">
          {{item.create_time}}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CommonTopSearch from '@/views/components/COMMON_TOP_SEARCH/index.vue'
import { getTeacherPaperList } from '@/api/teacherPaper.js'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
let router = useRouter()
let paperList = ref([])
const toPaperInfo = (row) => {
  let form = {
    edu_paper_id: row.edu_paper_id,
  }
  window.sessionStorage.removeItem('testpaperCardIndex')
  window.sessionStorage.removeItem('paper')
  window.sessionStorage.removeItem('images')
  router.push('doPaper?isTeacherPaper=1&form=' + JSON.stringify(form))
}
const getPaperList = async (id, keywords) => {
  let params = {
    subject_id: id,
    keyword: keywords,
  }
  const { data } = await getTeacherPaperList(params)
  paperList.value = data.list
}

</script>

<style lang="scss" scoped>
.my_test_paper {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-image: url("../../static/allBackg/bg-教师试卷.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.papers {
  display: flex;
  flex-wrap: wrap;
  // background-color: #1955e3;
  // border-radius: 25px;
  width: 100%;
  margin: 40rem 80rem;
  overflow-y: scroll;
  height: calc(100vh - 280rem);
  .paper {
    position: relative;
    width: 410rem;
    height: 380rem;
    background-image: url("../../static/teacher_paper/bg-教师试卷.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 30rem;
    margin-right: 40rem;
    overflow: hidden;
    .warp {
      box-shadow: 0px 1px 4px 0px rgba(230, 170, 86, 1);
      border-radius: 30rem;
      background: #ffffff;
      width: 350rem;
      height: 190rem;
      margin: 76rem auto 0;
      overflow: hidden;
      .teacher_name {
        height: 50rem;
        width: 350rem;
        background: #efa845;
        font-size: 20rem;
        font-weight: 500;
        color: #ffffff;
        line-height: 28rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .content {
        position: relative;
        width: 318rem;
        height: 140rem;
        font-size: 22rem;
        margin: 0 auto;
        // font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #b05c27;
        line-height: 30rem;
        padding-top: 16rem;
        background-color: #ffffff;
        font-weight: bold;
        text-align: center;

        .content_txt {
          height: 60rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .score {
          font-weight: 500;
          color: #b76c3c;
          font-size: 20rem;
          position: absolute;
          bottom: 16rem;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .btn_warp {
      margin-top: 20rem;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        height: 44rem;
        width: 150rem;
        font-size: 22rem;
        background: #ff654e;
        border-radius: 30px;
        color: #fff;
        text-align: center;
        cursor: pointer;
        line-height: 44rem;
      }
    }
    .time {
      margin-top: 10rem;
      text-align: center;
      font-size: 18rem;
      font-weight: 500;
      color: #b76c3c;
    }
  }
}
</style>